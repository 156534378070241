<div [style.max-width.px]="600">
  @if (loading$ | async) {
    <div [style.padding.px]="20">
      <kdl-loading-indicator></kdl-loading-indicator>
    </div>
  } @else {
    <span kdlI18n label="conflictTitle" matDialogTitle></span>

    <div [style.padding]="'0px 20px 20px 20px'">
      <p kdlI18n label="conflictMessage"></p>
      <p>Please Choose:</p>
      <ul>
        <li>
          Force Update to overwrite the latest changes with your version.
          <p><em>Their last changes will be lost.</em></p>
        </li>
        <li>
          Reload the Page to see the latest version.
          <p><em>Your last changes will be lost.</em></p>
        </li>
      </ul>
      <p>
        Last Updated by {{ modifiedBy$ | async }} at {{ lastUpdated$ | async | date: 'mediumTime' }}
      </p>
    </div>
    <mat-dialog-actions>
      <button
        mat-raised-button
        color="warn"
        (click)="forceUpdate()"
      >
        Force Update
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="window.location.reload()"
      >
        Reload Page
      </button>
    </mat-dialog-actions>
  }
</div>
