import {Component, inject} from '@angular/core'
import {MatDialogRef} from '@angular/material/dialog'

import {finalize, Observable, shareReplay, Subject, switchMap} from 'rxjs'
import {map, take, tap} from 'rxjs/operators'

import {WINDOW} from '../../application/window.provider'
import {OpenProjectService} from '../../services/open-project.service'
import {ProjectService} from '../../services/project.service'

@Component({
  selector: 'kdl-conflict-dialog',
  templateUrl: './conflict-dialog.component.html'
})
export class ConflictDialogComponent {
  protected window = inject(WINDOW)
  protected openProjectService = inject(OpenProjectService)
  protected projectService = inject(ProjectService)
  protected dialogRef = inject(MatDialogRef)

  loading$ = new Subject<boolean>()

  latestProject$ = this.openProjectService.project$
    .pipe(
      switchMap(({ id }) => this.projectService.getProject(id, '$LATEST')),
      shareReplay(1),
    )
  lastUpdated$: Observable<Date> = this.latestProject$
    .pipe(
      map(([{ timeStamp }]) => new Date(timeStamp)),
    )
  modifiedBy$ = this.latestProject$
    .pipe(
      map(([{ modifiedBy }]) => modifiedBy),
    )

  forceUpdate() {
    this.loading$.next(true)

    this.openProjectService.forceProjectUpdate()
      .pipe(
        take(1),
        tap(() => this.dialogRef.close()),
        finalize(() => this.loading$.next(false)),
      )
      .subscribe()
  }
}
